<template>
  <div>
     <payhead title="最近12个月历史水费记录"></payhead>
     <div>
       <svg-icon class="back" icon-class="go-back" @click="jumpPayment"></svg-icon>
       <van-form @submit="onSubmit">
         <!-- 允许输入正整数，调起纯数字键盘 -->
         <van-search
             v-model="waterCode"
             shape="round"
             background="#4fc08d"
             type="digit"
             show-action
             @search="onSearch"
             placeholder="请输入缴费户号8位或12位数字"
         >
           <template #action>
             <div @click="onSearch">搜索</div>
           </template>
         </van-search>
       </van-form>
       <div class="interval"></div>
     </div>
    <div v-for="itme in list">
      <van-row type="flex" justify="center">
        <van-col span="22">{{itme.cmym}}</van-col>
      </van-row>
     <div class="card">
       <div class="intervalcard"></div>
       <van-row type="flex" justify="center">
         <van-col span="22">{{itme.sbbh}} | {{itme.yhmc}} | {{itme.yhdz}}</van-col>
       </van-row>
       <div class="intervalcard"></div>
      <van-row type="flex" justify="center">
        <van-col span="10"><span>上月抄码:</span>{{itme.dycm}}</van-col>
        <van-col span="10"><span>当月抄码:</span>{{itme.dyqm}}</van-col>
      </van-row>
      <div class="intervalcard"></div>
      <van-row type="flex" justify="center">
        <van-col span="10"><span>当月用水:</span>{{itme.sstw}} 吨</van-col>
        <van-col span="10"><span>当月水费:</span>{{itme.qfje}} 元</van-col>
      </van-row>
       <div class="intervalcard"></div>
     </div>
      <div class="intervalcards"></div>
    </div>
    <div class="connect" @click="jumpPayment">我要去缴费</div>
  </div>
</template>

<script>
import payhead from '../components/payhead'
import { mapState } from 'vuex'
import request from "@/api/index.js";
export default {
  name: '',
  components: {
    payhead
  },
  props: {},
  data() {
    return {
      waterCode:'',
      from:{
        waterCode:'',
      },
      list:[

      ]
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
    let da= this.$route.query.waterCode
    if (da){
      this.waterCode=da
    }
  },
  mounted() {

  },
  methods: {
    onSearch(value){
      request.get('/pay/waterFee/history',{params:{waterCode:this.waterCode}}).then(res=>{
        this.list=res.data
      })
    },
    onSubmit(values){
      console.log('submit', this.from);
    },
    jumpPayment(){
      console.log("----")
      this.$router.push({path: '/', query: {waterCode:this.waterCode}})
    }
  },
}
</script>
<style lang='scss' scoped>
.interval{
  height:10px;
  width:100%;
}
.intervalcard{
  height:5px;
  width:100%;
}
.intervalcards{
  height:20px;
  width:100%;
}
span{
  color: #999;
}
.card{
  background-color: white;
  border-radius:10px;
}
.connect{
  text-align: center;
  margin-top:30px;
  color: blue;
  padding-bottom:30px;
}
.back{
  width: 50px;
  height:54px;
  float: left;
  color:#ffffff;
  background-color:#4fc08d;
}
</style>
